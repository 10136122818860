@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

._3curk {
  font-family: 'Roboto', sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation: _3uImN 0.5s;
          animation: _3uImN 0.5s;
}

._3cxVN {
  width: 90vw;
  max-width: 300px;
  background-color: white;
  border-radius: 0.5rem;
  overflow: hidden;
  -webkit-animation: _J_W1U 300ms;
          animation: _J_W1U 300ms;
}

._1aDvk {
  padding: 1rem;
  border-bottom: 1px solid #ccc;
  background-color: #4527A0;
  color: white;
}
._2KezC {
  margin-top: 0;
  margin-bottom: 0.5rem;
  opacity: 0.75;
}
._oZdUT {
  padding: 1rem;
  display: flex;
}
button:disabled {
  opacity: 0.5;
}
._yof3Q {
  flex: 1;
}
._yof3Q select {
  margin-right: 0.5rem;
  border: none;
  background-color: #efefef;
  padding: 0.25rem;
  border-radius: 0.25rem;
}
._3gTMH {
  display: flex;
  align-items: center;
}
button._2s7Q2 {
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}
button._2s7Q2:first-child {
  margin-right: 1rem;
}
._NWZcI {
  font-size: 1.25rem;
  font-weight: 500;
}
._38Og6 {
  font-size: 1rem;
}
._3KAu7 {
  padding: 0 1rem 0.5rem 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #ccc;
  font-weight: 500;
  font-size: 0.75rem;
}

._1yUS2 {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: 1rem;
}
._1hglr {
  height: 2rem;
  font-size: 0.85rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
._1hglr button {
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 100%;
  -webkit-animation: _J_W1U 300ms;
          animation: _J_W1U 300ms;
}
._2La4E {
  opacity: 0.5;
}
._1sbNu button {
  font-weight: 500;
  
}


._1hglr button:hover {
  background-color: #eee !important;
  color: #000 !important;
  
}
._24OKz {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

._24OKz button {
  color: #4527A0;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
}
._24OKz button:hover {
  opacity: 0.6;
}

._24OKz button:disabled {
  cursor: default;
  opacity: 0.5;
}

._31Z79 {
  -webkit-animation: _31Z79 0.3s;
          animation: _31Z79 0.3s;
}

._3y6_k {
  -webkit-animation: _3y6_k 0.3s;
          animation: _3y6_k 0.3s;
}

@-webkit-keyframes _31Z79 {
  0% {
    transform: scale(1);
  }
  
  100% {
    transform: scale(0.5) translateY(50%);
  }
}

@keyframes _31Z79 {
  0% {
    transform: scale(1);
  }
  
  100% {
    transform: scale(0.5) translateY(50%);
  }
}

@-webkit-keyframes _J_W1U {
  0% {
    transform: scale(0.5) translateY(50%);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes _J_W1U {
  0% {
    transform: scale(0.5) translateY(50%);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes _3uImN {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes _3uImN {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-webkit-keyframes _3y6_k {
  0% {
    opacity: 1;
  }
  
  100% {
    opacity: 0;
  }
}
@keyframes _3y6_k {
  0% {
    opacity: 1;
  }
  
  100% {
    opacity: 0;
  }
}
